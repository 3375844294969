    /* Базовые стили таблицы */
  .vacation-table {
    border-collapse: collapse;
    width: max-content; /* Устанавливаем ширину, чтобы поддерживать горизонтальный скролл */
    font-size: 12px;
    position: relative;
  }
  
  .vacation-table th,
  .vacation-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  /* Закрепление первого столбца */
  .vacation-table td:first-child,
  .vacation-table th:first-child {
    position: sticky;
    left: 0; /* Закрепляем первый столбец */
    z-index: 2; /* Устанавливаем z-index для первого столбца ниже, чем у шапки */
  }
  
  /* Закрепление строки с месяцами */
  .vacation-table .month-header th {
    position: sticky;
    top: 0; /* Закрепляем строку с месяцами сверху */
    z-index: 3; /* Более высокий z-index для строки с месяцами */
  }
  
  /* Закрепление строки с днями */
  .vacation-table .day-header th {
    position: sticky;
    top: 30px; /* Расстояние до строки с месяцами */
    z-index: 2; /* Более низкий z-index для строки с днями */
  }
  
  /* Стили для выделения отпусков */
  .vacation-table .vacation {
    background-color: #4caf50; /* Цвет для дней отпуска */
  }
  
.selected-row {
  border: 1px solid #4caf50 !important;
}

.breadcrumbs-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  color: #384C71;
}

.breadcrumbs-start-item{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breadcrumbs-title{
  margin-left: 5px;
}

.title-page{
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #384C71;
  margin-top: 10px;
}

.title-page-container{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.table-all-vac{
  width: 100%;
  height: 100vh;
  background-color: #EFF2F7;
}

.table-all-vac-container{
  display: flex;
  flex-direction: column;
  width: 90%;
  height: fit-content;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: #EFF2F7;
}

.vacation-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0;
    background-color: #384C71; /* Цвет фона */
    z-index: 5;
  }

  th {
    padding: 10px;
    border: 1px solid #8FA5BF;
    color: #8FA5BF;
    text-align: center;
  }
}

.table-container {
  max-height: 600px; /* Задайте нужную высоту */
  overflow-y: auto; /* Включает вертикальную прокрутку */
  width: 100%;
}

.table-all-vac-legend-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
}

.table-all-vac-legend-container .legend-item{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-all-vac-legend-container .legend-item:nth-child(n+1){
  margin-left: 10px;
}

.table-all-vac-legend-container .color{
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.table-all-vac-legend-container .title-legend{
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  color: #384C71;
  margin-left: 5px;
}

.month-item{
  transition: background-color 0.6s;
  background-color: "#384C71";
}

.month-item:hover{
  background-color: #222F46;
  cursor: pointer;
}

.table_all_vac_cont::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.table_all_vac_cont::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #384C71;
}

.table_all_vac_cont::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}