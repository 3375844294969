.time_container {
  display: flex;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  margin-right: 30px;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.time_title {
  margin-left: 20px;
  margin-right: 20px;
}
.employed_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MuiChartsAxis-tickContainer {
  display: none;
}
.graph_container {
  display: flex;
  flex-direction: row;
}

.light-red-row {
  background-color: #fac4c4; 
}

.light-red-row:hover {
  background-color: #c48585 !important;
}
