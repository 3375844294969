@import './main.scss';

.control_container{
    width: 1600px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat", sans-serif;
}
.navbar_control{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 800px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.btn_m{
    margin-bottom: 20px;
    display: flex;
    width: 550px;
    justify-content: space-between;
}
.cntrl{
    width: 320px;
    height: 68px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    border-radius:  6px;
    justify-content: space-between;
    padding: 15px;
}
.title_cntrl{
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10px;
    font-size: 14px;
}
.num_cntrl{
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #ffaa00;
}
.table_cont_control{
    display: flex;
    width: 1600px;
    justify-content: space-around;
}

.modal_upd_lmk {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal_cont_upd_lmk{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    flex-direction: column;
    width: 320px;
    height: auto;
}

.modal_cont_upd_lmk button{
  width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-family: $main_font;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
  cursor: pointer;
}
.modal_cont_upd_lmk  button:hover{
  background-color: #1565C0;
  transition: background-color 0.3s ease;
}

.modal_upd_siz{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
.modal_cont_upd_siz{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 380px;
}
.modal_cont_upd_siz button{
  width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-family: $main_font;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
  cursor: pointer;
}
.modal_cont_upd_siz  button:hover{
  background-color: #1565C0;
  transition: background-color 0.3s ease;
}
