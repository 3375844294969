.modal_content_dataChange{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column; 
    width: 300px;
    height: auto; 
    align-items: center;
}