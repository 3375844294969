@import './main.scss';

.add_lmk{
    margin: 0px 0px 20px 0px;
    width: 150px;
    height: 45px;
    font-family: $main_font;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: $main_color;
}
.add_lmk:hover{
    background-color: $main_hover_color;
    transition: background-color 0.3s ease;
}

.modalTableLmk {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
  }
  
.modal-contentLmk {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 300px;
    height: 350px;
    flex-direction: column;
}
input[name="datelmk"]{
    width: 292px;
    margin-top: 10px;
    margin-bottom: 10px;
}
input[name="dateflg"]{
    width: 292px;
    margin-top: 10px;
    margin-bottom: 10px;
}
input[name="dateadsm"]{
    width: 292px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.modal-actionsLmk{
    width: 292px;
    display: flex;
    justify-content: center;
}
.modal-actionsLmk button{
    width: 100px;
    height: 30px;
    margin-top: 10px;
    font-family: $main_font;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #1976d2;
}
.modal-actionsLmk button:hover{
    background-color: #1565C0;
    transition: background-color 0.3s ease;
}
.modal-actionsLmk button:last-child{
    margin-left: 10px;
}
.lmk_cont{
    width: 100%;
}
.table_cont4{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}