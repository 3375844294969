.container_print_efes{
    width: 100%;
    height: calc(100vh - 20px);
    padding: 20px 0px 0px 20px;
    background-color: #eff2f7;
}

.block_print_more_efes {
    width: 355px;
    height: 110px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: row;
    display: flex;
    margin-top: 15px;
    margin-left: 15px;
    padding: 20px 25px;
    flex-direction: column;
}

.modal_print_journal_efes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}

.modal_cont_print_journal_efes {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    width: 340px;
    height: 590px;
    flex-wrap: wrap;
    align-content: flex-start;
}

.modal_cont_print_journal_efes button{
    width: 100px;
    height: 30px;
    margin: 10px 10px 0px 10px;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #1976d2;
    font-family: "Montserrat", sans-serif;
}

.modal_cont_print_journal_efes  button:hover{
    background-color: #1565C0;
    transition: background-color 0.3s ease;
}

#scroll_post::-webkit-scrollbar {
    width: 10px;
    background-color: #f9f9fd;
}
  
#scroll_post::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #1976d2;
}
  
#scroll_post::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #f9f9fd;
}