.react-dual-listbox{
    width: 700px;
    height: 600px;
}

.rdl-actions{
    align-self: center;
}

.rdl-move-all-to-selected, .rdl-move-all-to-available{
    display: none;
}
