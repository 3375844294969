.page_tabel {
  width: calc(100% - 78px);
  height: 100vh;
  background-color: #eff2f7;
}

.page_tabel_container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: fit-content;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: #eff2f7;
}

.menu_tabel {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.table_tabel_cont::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.table_tabel_cont::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #384c71;
}

.table_tabel_cont::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

.table_tabel_cont thead {
  position: sticky;
  top: 0;
  background-color: #384c71;
  z-index: 5;
}

.header_tabel_all div {
  border: 1px solid #222f46;
  text-align: center;
  padding: 8px;
  border-right: 0px;
}

.table_tabel_cont tbody td {
  border: 1px solid #acbbce;
  text-align: center;
  padding: 8px;
}

.header_tabel_all {
  padding: 0px;
}

.header_tabel_day {
  background: linear-gradient(#fb8a01, #bab709);
  padding: 4px 8px;
  color: #9d431d;
}

.header_tabel_night {
  background: linear-gradient(#022d81, #0b638f);
  padding: 4px 8px;
  color: #d5faff;
}

.table_tabel_cont table {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #8fa5bf;
}

.modal_tabel_mars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal_tabel_mars_cont {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
}

.btn_modal_tabel_mars {
  width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
  font-family: "Montserrat", sans-serif;
}

.btn_modal_tabel_mars:hover {
  background-color: #1565c0;
  transition: background-color 0.3s ease;
}

.day_night_header:hover{
  cursor: pointer;
  filter: brightness(0.8);
}
