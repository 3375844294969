.briefings_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn_brief_change Button{
    margin: 10px;
}
.btn_m2 Button{
    margin: 10px;
}