.block_employee_num_settings_efes {
    width: 255px;
    height: 430px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: row;
    display: flex;
    margin-top: 15px;
    margin-left: 15px;
    padding: 20px 25px;
    flex-direction: column;
}

.table_settings_container_efes{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.modal_add_post_employee_efes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal_cont_add_post_employee_efes {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    width: 340px;
    height: 120px;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
}

.modal_cont_add_post_employee_efes button{
    width: 100px;
    height: 30px;
    margin: 20px 10px 0px 10px;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #1976d2;
    font-family: "Montserrat", sans-serif;
  }
.modal_cont_add_post_employee_efes  button:hover{
    background-color: #1565C0;
    transition: background-color 0.3s ease;
}
.modal_cont_add_post_employee_efes textarea{
    resize: none;
    margin-top: 5px;
    width: calc(100% - 20px);
    font-family: "Montserrat", sans-serif;
    border-radius: 3px;
    padding: 10px;
}

.settting_container {
    background-color: #eff2f7;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.modal_add_commission_employee_efes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal_cont_add_commission_employee_efes {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    width: 340px;
    height: 680px;
    flex-wrap: wrap;
    align-content: flex-start;
}

.modal_cont_add_commission_employee_efes button{
    width: 100px;
    height: 30px;
    margin: 10px 10px 0px 10px;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #1976d2;
    font-family: "Montserrat", sans-serif;
}

.modal_cont_add_commission_employee_efes  button:hover{
    background-color: #1565C0;
    transition: background-color 0.3s ease;
}


.modal_add_program_brief_employee_efes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}

.modal_cont_add_program_brief_employee_efes {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    width: 340px;
    height: 440px;
    flex-wrap: wrap;
    align-content: flex-start;
}

.modal_cont_add_program_brief_employee_efes button{
    width: 100px;
    height: 30px;
    margin: 10px 10px 0px 10px;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #1976d2;
    font-family: "Montserrat", sans-serif;
}

.modal_cont_add_program_brief_employee_efes  button:hover{
    background-color: #1565C0;
    transition: background-color 0.3s ease;
}

.setting_subnavbar_efes{
    height: 55px;
    width: 100%;
    background-color: #1976d2;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 5px solid #1976d2;
}

.setting_content_efes{
    width: 100%;
    background-color: #eff2f7;
    height: calc(100vh - 60px);
}

.element_subnavbar_efes{
    font-weight: 700;
    font-size: 18px;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    height: 55px;
    border-top: 5px solid #1976d2;
    display: flex;
    align-items: center;
}

.element_subnavbar_efes:hover{
    border-top: 5px solid #0c3e77;
    background-color: #1565C0;
    transition: all 0.3s ease;
}

.modal_cont_add_program_brief_employee_efes textarea{
    resize: none;
    margin-top: 5px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10px;
    margin-top: 5px;
}