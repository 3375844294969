.active_employee_efes{
    width: 100%;
}

.container_normal_siz_employee_efes{
    margin-left: auto;
    margin-right: auto;
    width: 1050px;
}

.container_history_employee_efes{
    margin-left: auto;
    margin-right: auto;
    width: 1605px;
}

.container_active_employee_efes{
    margin-left: auto;
    margin-right: auto;
    width: 1280px;
}

.container_briefings_efes{
    margin-left: auto;
    margin-right: auto;
    width: 880px;
}

.container_siz_efes{
    margin-left: auto;
    margin-right: auto;
    width: 680px;
}

.container_fired_efes{
    margin-left: auto;
    margin-right: auto;
    width: 810px;
}

.time_container_fired_efes{
    margin-left: auto;
    margin-right: auto;
    width: 810px;
    display: flex;
    flex-direction: row;
}

.time_container_fired_efes{
    height: 70px;
    margin-top: 20px;
}
.time_container_fired_efes .css-10o2lyd-MuiStack-root>.MuiTextField-root, .css-10o2lyd-MuiStack-root>.MuiPickersTextField-root{
    min-width: 100px;
}

.time_container_fired_efes .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 7px 5px;
}

.time_container_fired_efes label{
    top: -10px;
}

.time_container_fired_efes .time_title{
    margin-left: 0px;
    margin-top: 4px;
    width: 150px;
}

.time_container_fired_efes .time_title2{
    margin-left: 10px;
    margin-top: 14px;
    margin-right: 10px;
}

.time_container_fired_efes .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-focused{
    top: 0px;
}

//Модальное окно
.modal_add_employee_efes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal_content_add_employee_efes {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    width: 750px;
    height: 382px;
    flex-wrap: wrap;
    align-content: flex-start;
}

.pers_info_employee_efes2{
    min-width: 400px;
    max-width: 400px;
}

.pers_info_employee_efes1{
    min-width: 300px;
    max-width: 300px;
}

.title_employee_efes{
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #1976d2;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
    height: fit-content;
}

.modal_fired_employee_efes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal_cont_fired_employee_efes {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 280px;
}

.modal_cont_fired_employee_efes button{
    width: 100px;
    height: 30px;
    margin: 20px 10px 0px 10px;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #1976d2;
    font-family: "Montserrat", sans-serif;
  }
  .modal_cont_fired_employee_efes  button:hover{
    background-color: #1565C0;
    transition: background-color 0.3s ease;
  }
  .modal_cont_fired_employee_efes textarea{
    resize: none;
    margin-top: 5px;
    width: calc(100% - 20px);
    font-family: "Montserrat", sans-serif;
    border-radius: 3px;
    padding: 10px;
  }