@import "./main.scss";
.siz_cont{
    width: 100%;
}
.table_cont2{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
.modal-contentTableEmpl2{
    height: 400px;
}
.add_siz{
    margin: 0px 0px 20px 0px;
    width: 150px;
    height: 30px;
    font-family: $main_font;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: $main_color;
}
.add_siz:hover{
    background-color: $main_hover_color;
    transition: background-color 0.3s ease;
}