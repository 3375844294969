@import "./main.scss";
.employee_cont1{
    font-family: $main_font;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main_cont{
    width: 100%;
}
.add_emploee{
    margin: 20px 0px 0px 0px;
    width: 150px;
    height: 30px;
    font-family: $main_font;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: $main_color;
}
.add_emploee:hover{
    background-color: $main_hover_color;
    transition: background-color 0.3s ease;
}
//Модальное окно
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal-content-empl {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    display: flex;
    width: 680px;
    height: fit-content;
  }
  
  .pers_info {
    width: 300px;
    height: 620px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: baseline;
  }
  

.modal_fired {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal_cont_fired {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 280px;
}

.modal_cont_fired button{
  width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-family: $main_font;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
}
.modal_cont_fired  button:hover{
  background-color: #1565C0;
  transition: background-color 0.3s ease;
}
.modal_cont_fired textarea{
  resize: none;
  font-family: $main_font;
  margin-top: 5px;
  width: 100%;
}
.modal_cont_fired .dat{
  display: inline;
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal_act_fir{
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #000;
}
h3 {
    margin-top: 0px;
    font-family: $main_font;
}
label {
    font-family: $main_font;
    font-weight: 500;
    margin-right: 15px;
}
input{
    font-family: $main_font;
}
input[name="fio"] {
    width: 235px;
}
.gen{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
input[name="datebirthday"] {
    width: 141px;
    margin-bottom: 10px;
}
label[htmlFor="datebirthday"] {
    margin-bottom: 10px;
}
select{
    font-family: $main_font;
}
input[name="dateemployment"] {
    margin-top: 10px;
    width: 290px;
    margin-bottom: 20px;
}
.add_send_emploee{
    margin: 20px 75px 0px 75px;
    width: 150px;
    height: 30px;
    font-family: $main_font;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #1976d2;
}
.add_send_emploee:hover{
    background-color: #1565C0;
    transition: background-color 0.3s ease;
}
.st_title{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    margin-right: 30px;
    font-size: 18px;
}

// Модальное окно добавления отпуска
.modal_vacation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}
  
.modal_cont_vacation {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 180px;
}

.modal_cont_vacation button{
  width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-family: $main_font;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
  cursor: pointer;
}
.modal_cont_vacation  button:hover{
  background-color: #1565C0;
  transition: background-color 0.3s ease;
}
.dat_vac{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.modal_act_vacation{
    display: flex;
    justify-content: center;
}
.MuiDataGrid-cell .MuiDataGrid-cellEmpty{
    display: none;
}