.modal_notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0; 
    visibility: hidden; 
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Transition for smooth fading */
}

.modal_notification.visible {
    opacity: 1;
    visibility: visible;
}

.container_notification {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column; 
    width: 300px;
    height: auto; 
    align-items: center;
}

.icon_notification {
    margin-bottom: 10px;
}

.title_notification {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.subtitle_notification {
    font-size: 14px;
    margin-bottom: 20px;
}

.close_button,
.action_button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.btn_notif{
    width: 70%;
    display: flex;
    justify-content: space-around;
}

.close_button {
    background-color: #1565C0; 
}

.action_button {
    background-color: #1565C0;
}