.print_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.info_print_cont{
    margin-right: 50px;
}

.btn_cont_printf{
    display: flex;
    flex-direction: column;
}