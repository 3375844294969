@import './main.scss';

.homepage_header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}
.homepage_container{
    width: 100%;
    height: 100vh;
}
.header_title_info{
    font-family: "PT Sans", sans-serif;
    margin-left: 15px;
}

.header_title_info div {
    font-weight: 400;
    font-size: 16px;
    color: #2F3747;
}

.header_title_info div:last-child {
    font-weight: 700;
    font-size: 25px;
}

.homepage_container{
    background-color: #eff2f7;
}

.homepage_dashboard_container{
    margin-top: 5px;
    margin-right: 15px;
    font-family: "PT Sans", sans-serif;
    color: #2F3747;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1200px;
}

.block_employee_num{
    width: 255px;
    height: 97px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-left: 15px;
}

.circle_dashboard{
    width: 56px;
    height: 56px;
    background-color: #eff2f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info_employee_num{
    margin-left: 10px;
}
.info_employee_num div:last-child{
    font-weight: 700;
    margin-top: 5px;
    font-size: 18px;
}

.block_employee_barchat_shift{
    width: 535px;
    height: 350px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 15px;
    font-family: "PT Sans", sans-serif;
    padding-top: 15px;
    padding-bottom: 15px;
}
.block_employee_barchat_shift div:first-child{
    //font-weight: 700;
    font-size: 16px;
}

.nextControl{
    cursor: pointer;
    transition: all 0.5s;
}

.nextControl:hover{
    background-color: #dee8f8;
}