@import './main.scss';

.modalTableTraining {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
  }
  
.modal-contentTableTraining {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    position: relative; 
    display: flex;
    width: 300px;
    height: 540px;
    flex-direction: column;
    align-items: center;
}
input[name="date"]{
    width: 225px;
    margin-top: 10px;
    margin-bottom: 10px;
}
input[name="dateend"]{
    width: 225px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.add_train{
    margin: 0px 0px 20px 0px;
    width: 150px;
    height: 30px;
    font-family: $main_font;
    font-weight: 600;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: $main_color;
}
.add_train:hover{
    background-color: $main_hover_color;
    transition: background-color 0.3s ease;
}
.fg{
    display: flex;
    width: 225px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.fg2{
    display: flex;
    width: 225px;
    margin-top: 10px;
    margin-bottom: 0px;
}
input[name="numsmen"]{
    width: 93px;
}
.area_cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.table_cont5{
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
select{
    height: 36.5px;
    width: 121px;
    border-radius: 3px;
}
.act_btn_area{
    width: 250px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-contentTableTraining button{
    width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-family: $main_font;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
}
.modal-contentTableTraining  button:hover{
  background-color: #1565C0;
  transition: background-color 0.3s ease;
}
.tr_cont_select{
    width: 100%;
    margin-bottom: 15px;
}
.tr_cont_select:last-child{
    margin-bottom: 0px;
}