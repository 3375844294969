@import './main.scss';

.vacations_cont{
  font-family: $main_font;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.vacations_cont th, .vacations_cont td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.vacations_cont td {
  cursor: pointer;
}

.mainarea-1 {
  background-color: rgb(252, 59, 59); /* Primary area color */
}

.mainarea-2 {
  background-color: rgb(255, 209, 82); /* Primary area color */
}

.mainarea-3 {
  background-color: rgb(180, 255, 82); /* Primary area color */
}

.mainarea-4 {
  background-color: rgb(82, 255, 255); /* Primary area color */
}

.mainarea-5 {
  background-color: rgb(82, 99, 255); /* Primary area color */
}

.mainarea-6 {
  background-color: rgb(171, 82, 255); /* Primary area color */
}

.mainarea-7 {
  background-color: rgb(255, 82, 218); /* Primary area color */
}

.mainarea-8 {
  background-color: rgb(255, 114, 96); /* Primary area color */
}

.mainarea-9 {
  background-color: rgb(58, 78, 11); /* Primary area color */
}

.default-area{
  background-color: rgb(83, 83, 83); 
}


.modal_delete_vacation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
}
  
.modal_cont_delete_vacation {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative; 
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 180px;
}

.modal_cont_delete_vacation button{
  width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-family: $main_font;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
  cursor: pointer;
}
.modal_cont_delete_vacation  button:hover{
  background-color: #1565C0;
  transition: background-color 0.3s ease;
}
.shift-green{
  background-color: #06b900;
}
.shift-orange{
  background-color: #b97e00;
}
.shift-red{
  background-color: #b92500;
}
.shift-yellow{
  background-color: #e7d800;
}
.shift-dec{
  background-color: #df00e7;
}
.shift-rnd{
  background-color: #c367ee;
}
.shift-pet{
  background-color: #6f4ffd;
}
.shift-pod{
  background-color: #4fdafd;
}
.shift-svo{
  background-color: #4ffdc3;
}