.modal_brief_employee_efes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal_cont_brief_employee_efes {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 350px;
}

.modal_cont_brief_employee_efes button {
  width: 100px;
  height: 30px;
  margin: 20px 10px 0px 10px;
  font-weight: 600;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  background-color: #1976d2;
  font-family: "Montserrat", sans-serif;
}

.modal_cont_brief_employee_efes button:hover {
  background-color: #1565c0;
  transition: background-color 0.3s ease;
}
